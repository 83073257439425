export default {
  "app": {
    "components": {
      "logoutModal": {
        "title": "Sesión Expirada",
        "content": "Su sesión ha expirado debido a ${duration} minutos de inactividad. Será redirigido a la página de inicio de sesión.",
        "okButton": "Ok"
      },
      "industryOptions": {
        "construction": "Construcción",
        "mining": "Minería",
        "agriculture": "Agricultura",
        "droneOperator": "Operador de Drones",
        "environment": "Medio Ambiente",
        "consulting": "Consultoría"
      },
      "coordinateViewer": {
        "moreInfo": "Más Información",
        "changeCoordinateSystem": "Cambiar Sistema de Coordenadas",
        "header": "Seleccione el sistema de coordenadas",
        "subheader": "Busque y seleccione el sistema de coordenadas.",
        "cancel": "Cancelar",
        "change": "Modificar",
        "ok": "Confirmar"
      },
      "reportProgression": {
        "title": "Informe de Progresión",
        "coverTitle": "Informe de Progresión",
        "coverSubtitle": "Progreso Físico",
        "processingTitle": "Información de Procesamiento",
        "processingDetail": "Datos de Procesamiento",
        "processingName": "Nombre del Procesamiento",
        "processingDate": "Fecha de la Imagen",
        "referenceName": "Nombre del Proyecto de Referencia",
        "volumeCalc": "Resumen de Cálculo",
        "areaInfo": "Datos de Área",
        "areaType": "Tipo de Área",
        "areaValue": "Valor",
        "areaPlane": "Área Planar",
        "areaBase": "Área Base",
        "areaSurface": "Área de Superficie",
        "volumeInfo": "Datos de Volumen",
        "volumeType": "Método de Cálculo",
        "volumeName": "Nombre",
        "volumeCut": "Corte",
        "volumeFill": "Relleno",
        "volumeTotal": "Total",
        "orthomosaic": "Ortomosaico Combinado",
        "results": "Resultados",
        "failedToConnect": "No se pudo conectar con el servidor, inténtelo de nuevo.",
        "failedToGenerate": "No se pudo generar el informe de progresión."
      },
      "reportVolume": {
        "areaTitle": "Informe de Área",
        "title": "Informe de Volumen",
        "coverTitle": "Informe de Medición",
        "coverSubtitle": "Información de Volumen",
        "coverAreaSubtitle": "Información de Área",
        "processingTitle": "Información de Procesamiento",
        "processingDetail": "Datos de Procesamiento",
        "processingId": "ID de Procesamiento",
        "processingDate": "Fecha de la Imagen",
        "taskId": "ID de Tarea de Procesamiento",
        "gsd": "GSD/Resolución Promedio",
        "surfaceDetail": "Información de la Superficie",
        "coordinatesDetail": "Coordenadas del Polígono",
        "vertex": "Vértices",
        "volumeCalc": "Resumen de Cálculo",
        "areaInfo": "Datos de Área",
        "areaType": "Tipo de Área",
        "areaValue": "Valor",
        "areaPlane": "Área Planar",
        "areaBase": "Área Base",
        "areaSurface": "Área de Superficie",
        "volumeInfo": "Datos de Volumen",
        "volumeType": "Método de Cálculo",
        "volumeName": "Nombre",
        "volumeCut": "Corte",
        "volumeFill": "Relleno",
        "volumeTotal": "Total"
      },
      "reportViewer": {
        "title": "Informe de Precisión",
        "coverTitle": "INFORME DE PRECISIÓN",
        "processingHeader": "PROCESAMIENTO DE IMÁGENES",
        "createdAt": "Creado en",
        "summaryHeader": "Resumen",
        "summaryData": "Información de Procesamiento",
        "processingID": "ID de Procesamiento:",
        "flownAt": "Fecha de la Imagen:",
        "processingTaskID": "ID de la Tarea de Procesamiento:",
        "gsd": "GSD:",
        "user": "Usuario:",
        "camera": "Modelo de Cámara:",
        "processedAt": "Fecha de Procesamiento:",
        "area": "Área:",
        "validationHeader": "Validación de Calidad",
        "images": "Imágenes",
        "median": "Mediana de",
        "keyImages": "Puntos clave de la imagen",
        "matchesImages": "coincidencias por imagen",
        "dataHeader": "Conjunto de Datos",
        "optimization": "Optimización de cámara",
        "combination": "Combinación",
        "georef": "Georreferenciación",
        "crs": "Sistema de Coordenadas",
        "imageCRS": "Sistema de Coordenadas de la Imagen",
        "gcpHeader": "Sistema de Coordenadas de Puntos de Control Terrestres (GCP)",
        "outputCRS": "Sistema de Coordenadas de Salida",
        "overlapImages": "Imágenes Superpuestas:",
        "figureCaption": "Figura 2: Imágenes superpuestas calculadas para cada píxel.",
        "figureCaption2": "Las áreas rojas y amarillas indican una baja superposición de imágenes. Las áreas verdes indican una superposición más alta. Los modelos de alta calidad se generan con superposiciones de imágenes más altas.",
        "geoDetail": "Detalles de Georreferenciación",
        "gcp": "Puntos de Control Terrestres (GCP)",
        "gcpName": "Nombre del GCP",
        "gcpAccuracy": "Precisión XYz",
        "error": "Error",
        "projError": "Error de reproyección",
        "verified": "Verificado",
        "gcpCaption": "Precisión de ubicación por GCP y errores medios en las tres direcciones de coordenadas. La última columna cuantifica el número de imágenes calibradas donde el GCP fue verificado automáticamente frente a manualmente.",
        "variance": "Varianza de Geolocalización Absoluta",
        "geoError": "Error de Geolocalización",
        "calibrated": "${calibrated} de ${total} imágenes calibradas (${percentage}%), ${enabled} habilitadas",
        "optimized": "Porcentaje de diferencia relativa entre los parámetros iniciales y optimizados de la cámara interna.",
        "correspond": "Coincidencias por imagen calibrada.",
        "yesgcp": "Sí, ${gcpsLength} GCPs (${threeDLength} 3D), error rms promedio = ${rms} m",
        "nogcp": "sí no 3D GCP",
        "yesCheckPoint": "${length} Puntos de verificación (${threeDLength} 3D), error rms promedio = ${rms} m",
        "noCheckPoint": "Sin puntos de verificación",
        "errorCaption": "Los errores mínimos y máximos representan los rangos de error de geolocalización entre -1.5 y 1.5 veces la precisión máxima de todas las imágenes. Las columnas X, Y, Z muestran el porcentaje de imágenes con errores de geolocalización dentro de los rangos de error predefinidos. El error de geolocalización es la diferencia entre las posiciones iniciales y calculadas de la imagen. Tenga en cuenta que los errores de geolocalización de la imagen no corresponden a la precisión de los puntos 3D observados.",
        "errorNotFound": "INFORME NO ENCONTRADO",
        "cameraPositionSectionTitle": "Posiciones Iniciales y Optimizadas de la Cámara con la Ruta de Captura",
        "checkpoints": "Puntos de Verificación",
        "chkpDetail": "Detalles de Puntos de Verificación",
        "chkpName": "Punto de Verificación 3D",
        "chkpMarks": "Marcas",
        "mean": "Media"
      },
      "reportAxisTable": {
        "title": "Informe de Cálculo de Volumen",
        "axis": "Eje",
        "processing": "Procesamiento",
        "start": "Estaca Inicial",
        "end": "Estaca Final",
        "current": "NT: Current Flight",
        "primitive": "NT: Primitive Project",
        "reference": "NT: Reference Project",
        "processingDetail": "Datos de Cálculo de Volumen",
        "stake": "Estación",
        "areaCut": "Área de Corte",
        "areaFill": "Área de Relleno",
        "volumeCut": "Volumen de Corte",
        "volumeFill": "Volumen de Relleno",
        "accCut": "Volumen Acumulado de Corte",
        "accFill": "Volumen Acumulado de Relleno",
        "accTotal": "Volumen Total Acumulado"
      },
      "reportAxisGraph": {
        "title": "NT: Calculation Memorandum",
        "axis": "NT: Axis",
        "current": "NT: Current Flight",
        "primitive": "NT: Primitive Project",
        "reference": "NT: Reference Project",
        "processing": "NT: Processing",
        "processingDetail": "NT: Transversal Sections Graph",
      },
      "navigationControl": {
        "zoomIn": "Acercar",
        "zoomOut": "Alejar",
        "zoomExtents": "Extensión de Zoom",
        "resetNorth": "Restablecer al Norte"
      },
      "annotationsControl": {
        "marker": "Punto",
        "path": "Línea",
        "polygon": "Polígono"
      },
      "imageViewer": {
        "emptyMessage": "No se seleccionó ninguna imagen",
        "buttons": {
          "zoomIn": "Acercar",
          "zoomOut": "Alejar",
          "zoomExtents": "Restablecer Zoom",
          "close": "Cerrar",
          "previous": "Anterior",
          "next": "Siguiente",
          "information": "Información",
          "togglePip": "Alternar PIP",
          "download": "Descargar",
          "delete": "Eliminar",
          "rename": "Renombrar",
          "changeDate": "Cambiar Fecha"
        },
        "cancelUploadModal": {
          "title": "Cancelar cargas",
          "content": "Hay una o más cargas en progreso. ¿Desea cancelarlas?",
          "okButton": "Sí",
          "cancelButton": "No"
        }
      },
      "buttonUpload": {
        "label": "Subir imágenes",
        "corruptedModal": {
          "title": "Imágenes Corruptas",
          "content": "Algunas imágenes están corruptas. Por favor, revise las imágenes seleccionadas e intente nuevamente.",
          "okButton": "Ok"
        },
        "duplicatesModal": {
          "title": "Imágenes Duplicadas",
          "content": "Algunas imágenes ya existen y no es posible continuar la carga. Por favor, revise las imágenes seleccionadas e intente nuevamente.",
          "okButton": "Ok"
        },
        "restrictionsModal": {
          "title": "Tamaño máximo de la imagen",
          "content": "Algunas imágenes exceden el límite de 30Mb. Por favor, revise las imágenes seleccionadas e intente nuevamente.",
          "okButton": "Ok"
        },
        "noExifModal": {
          "title": "Imágenes sin geolocalización",
          "content": "Algunas imágenes no tienen geolocalización. ¿Desea continuar?",
          "okButton": "Continuar",
          "cancelButton": "Cancelar"
        }
      },
      "imagesPopover": {
        "noImageGCP": "Sin datos GPS en la imagen",
        "imageDetail": "Detalles de la Imagen",
        "filename": "Nombre del archivo",
        "uploadedBy": "Subido por",
        "position": "Posición",
        "altitude": "Altitud",
        "camera": "Cámara",
        "heading": "Encabezado"
      },
      "imagesList": {
        "table": {
          "filename": "Nombre del archivo",
          "uploadedBy": "Subido por",
          "capturedAt": "Fecha de captura",
          "uploadedAt": "Fecha de carga",
          "gps": "GPS",
          "status": "Estado",
          "loadingMessage": "Cargando, por favor espere...",
          "emptyMessage": "No se cargó ninguna imagen"
        },
        "loading": "Cargando...",
        "uploadImages": "Subir imágenes",
        "sucess": "Éxito",
        "error": "Error",
        "successfulRename": "Nombre de imagen editado.",
        "successfulRemove": "Imagen eliminada.",
        "failedRename": "Error al renombrar la imagen.",
        "failedRemove": "Error al eliminar la imagen.",
        "successfulDateChanged": "Fecha de captura modificada",
        "failedDateChanged": "Error al cambiar la fecha de captura",
        "headerOptions": {
          "download": "Descargar todo",
          "listView": "Visualizar lista",
          "gridView": "Visualizar cuadrícula"
        },
        "imageOptions": {
          "download_simple": "Descargar imagen",
          "download_forced": "Descargar imagen",
          "download": "Descargar imagen",
          "rename": "Renombrar",
          "remove": "Eliminar",
          "change_capture_date": "Editar la fecha de captura"
        },
        "removeModal": {
          "title": "Eliminar imagen",
          "content": "¿Está seguro de que desea eliminar la imagen <strong>''${imageName}'</strong>'?",
          "okButton": "Sí",
          "cancelButton": "No"
        },
        "removePermissionModal": {
          "title": "Acción no permitida.",
          "content": "Solo el usuario que subió la imagen puede eliminarla. Por favor contacte a<strong>${userName}</strong>",
          "okButton": "Ok"
        },
        "changeCapturedDatePermissionModal": {
          "title": "Acción no permitida.",
          "content": "Solo el usuario que subió la imagen puede eliminarla. Por favor contacte a <strong>${userName}</strong>",
          "okButton": "Ok"
        },
        "askAllDownloadModal": {
          "title": "Descargar imágenes",
          "content": "¿Desea descargar todas las imágenes?",
          "okButton": "Enviar",
          "cancelButton": "Cancelar"
        },
        "askSelectedDownloadModal": {
          "title": "Descargar imágenes",
          "content": "¿Desea descargar las imágenes seleccionadas?",
          "okButton": "Enviar",
          "cancelButton": "Cancelar"
        },
        "bulkConfirmationModal": {
          "title": "Procesamiento enviado",
          "content": "El enlace de descarga será enviado a su correo electrónico en cuanto el procesamiento esté completo.",
          "okButton": "Ok"
        },
        "footer": {
          "selectedImages": "${imagesQtd} imágenes seleccionadas",
          "selectedImage": "1 imagen seleccionada",
          "successfulBulkRename": "Nombres de imágenes modificados con éxito.",
          "successfulBulkRemove": "Imágenes eliminadas con éxito.",
          "successfulBulkDateChanged": "Fechas de captura modificadas con éxito.",
          "failedBulkRename": "Error al renombrar las imágenes.",
          "failedBulkRemove": "Error al eliminar las imágenes.",
          "failedBulkDateChanged": "Error al editar las fechas de captura",
          "removePermissionBulkModal": {
            "title": "Acción no permitida.",
            "content": "Solo los usuarios que subieron las imágenes pueden eliminarlas. Por favor, contacte a ellos.",
            "okButton": "Ok"
          },
          "changeCapturedDatePermissionBulkModal": {
            "title": "Acción no permitida.",
            "content": "Solo los usuarios que subieron las imágenes pueden eliminarlas. Por favor, contacte a ellos.",
            "okButton": "Ok"
          },
          "askChangeDatesModal": {
            "title": "Cambiar fechas",
            "content": "¿Está seguro de que desea cambiar las fechas?",
            "okButton": "Cambiar",
            "cancelButton": "Cancelar"
          },
          "askRemoveModal": {
            "title": "Eliminar imágenes",
            "content": "¿Está seguro de que desea eliminar las imágenes?",
            "okButton": "Eliminar",
            "cancelButton": "Cancelar"
          },
          "askRenameModal": {
            "title": "Renombrar imágenes",
            "content": "¿Está seguro de que desea renombrar las imágenes? El prefijo alterará todas las imágenes.",
            "placeholder": "Escriba el nombre de las imágenes aquí",
            "okButton": "Renombrar",
            "cancelButton": "Cancelar"
          }
        }
      }
    },
    "common": {
      "annotations": {
        "outsideBounds": "¡Error al crear la anotación! Anotación fuera del área de mapeo.",
        "outsideEdit": "Anotación fuera del área de mapeo.",
        "invalidAnnotation": "¡Error al crear la anotación! Anotación incompleta o con cruce de líneas.",
        "invalidEdit": "Anotación incompleta o con cruce de líneas."
      },
      "leaveRoute": {
        "title": "¿Está seguro de que quiere salir?",
        "okButton": "Sí, estoy seguro",
        "cancelButton": "Cancelar"
      },
      "userMenu": {
        "config": "Configuraciones",
        "switch-account": "Cambiar de cuenta",
        "help-center": "Ayuda",
        "logout": "Salir"
      },
      "roles": {
        "options": {
          "admin": "Admin",
          "operator": "Operador",
          "analyst": "Analista"
        },
        "waitingRegister": "Esperando Registro",
        "resubmitEmail": "Reenviar invitación",
        "successEmailSent": "Invitación enviada a ${email}"
      },
      "paginationBar": {
        "prev": "Ant",
        "next": "Sig",
        "of": "de"
      },
      "headerCaptions": {
        "flights": "Misiones",
        "processings": "Vuelos",
        "projects": "Proyectos"
      },
      "listHeader": {
        "filter": "Filtrar",
        "search": "Buscar",
        "placeholder": "Buscar ${type}...",
        "flights": "Misiones",
        "processings": "Procesamientos",
        "projects": "Proyectos"
      },
      "orderItems": {
        "date": { "title": "Fecha", "order": "desc" },
        "name": { "title": "Nombre", "order": "asc" }
      }
    },
    "changeAccount": {
      "components": {
        "accountCard": {
          "organization": "Organización:",
          "users": "Usuarios",
          "inactiveAccount": "Cuenta inactiva"
        }
      },
      "modal": {
        "title": "Cambiar cuenta"
      }
    },
    "settings": {
      "common": {
        "title": "Configuraciones",
        "closeButton": "Cerrar",
        "navigationItems": {
          "general": "General",
          "user_profile": "Perfil del Usuario",
          "account_info": "Información de la Cuenta",
          "members": "Miembros",
          "processings": "Procesamientos",
          "usage": "Consumo de Imágenes",
          "invoices": "Factura"
        },
        "dirtyModal": {
          "title": "Cerrar configuración",
          "content": "Hay cambios no guardados. ¿Desea salir?",
          "cancelButton": "No",
          "okButton": "Sí, salir sin guardar"
        }
      },
      "components": {
        "membersModal": {
          "newMemberTitle": "Agregar nuevo miembro",
          "editMemberTitle": "Editar miembro",
          "emailLabel": "Correo electrónico",
          "invalidDomain": "Escriba un correo de un dominio permitido: ${domains}",
          "invalidEmail": "Escriba un correo válido",
          "memberExists": "Miembro ya en la cuenta",
          "roleLabel": "Tipo",
          "roleDescriptions": {
            "analyst": "Puede visualizar y crear anotaciones dentro del proyecto.",
            "operator": "Puede crear y modificar proyectos, procesamientos y visualizar el consumo de imágenes.",
            "admin": "Gestiona miembros y acceso total."
          },
          "exportPermissionLabel": "Permiso de descarga: ¿puede el usuario exportar los datos?",
          "newMemberOkButton": "Agregar",
          "editMemberOkButton": "Actualizar",
          "cancelButton": "Cancelar"
        },
        "manageUserAvatar": {
          "loadImage": "Subir imagen",
          "imageRestrictions": "La imagen debe estar en formato JPG/PNG",
          "or": "o",
          "useInitials": "usar iniciales",
          "successfulUpdate": "Avatar modificado con éxito.",
          "failedUpdate": "Error al cambiar el avatar, intente nuevamente"
        },
        "editAvatarPictureModal": {
          "title": "Ajustar imagen",
          "cancelButton": "Cancelar",
          "okButton": "Guardar"
        },
        "changePasswordModal": {
          "title": "Cambiar contraseña",
          "oldPasswordLabel": "Contraseña actual",
          "newPasswordLabel": "Nueva contraseña",
          "newPasswordConfirmationLabel": "Confirmar nueva contraseña",
          "cancelButton": "Cancelar",
          "okButton": "Confirmar",
          "successfulUpdate": "Contraseña cambiada",
          "failedUpdate": "Hubo un error. Intente nuevamente.",
          "errors": {
            "required": "La contraseña no puede estar en blanco",
            "invalid-old-password": "Contraseña incorrecta",
            "eight-chars-min": "Mínimo de 8 caracteres",
            "invalid-confirmation": "La contraseña no coincide",
            "incorrect-pattern": "La contraseña debe tener al menos una minúscula, una mayúscula, un carácter especial y un número, con una longitud mínima de 8 caracteres"
          }
        },
        "sumProcessingStatus": {
          "processed": "Procesado",
          "processing": "Procesando",
          "failed": "Procesamiento fallido"
        },
        "processingAccordion": {
          "flightDate": "Fecha del vuelo:",
          "sentBy": "Enviado por",
          "status": "Estado",
          "storage": "Almacenamiento",
          "visualize": "Visualizar",
          "images": "imágenes",
          "accuracyReport": "Informe de Precisión"
        },
        "imageVisualizationModal": {
          "title": "Ver Mapa"
        }
      },
      "pages": {
        "general": {
          "appearance": "Tema",
          "dark": "Oscuro",
          "light": "Claro",
          "language": "Idioma",
          "measureUnit": "Unidades",
          "inclinationUnit": "Inclinación",
          "dateTime": "Fecha y hora",
          "dateFormat": "Formato de fecha",
          "timeFormat": "Formato de hora",
          "updateButtonLabel": "Actualizar",
          "successfulUpdate": "Actualización exitosa",
          "failedUpdate": "Hubo un problema. Intente nuevamente.",
          "languageOptions": {
            "pt": "Portugués",
            "en": "Inglés",
            "es": "Español"
          },
          "measureUnitOptions": {
            "metric": "Métrico",
            "imperial": "Imperial"
          },
          "inclinationUnitOptions": {
            "degrees": "Grados",
            "radians": "Radianes",
            "percentage": "Porcentaje"
          },
          "timeFormatOptions": {
            "twelve": "12 horas",
            "twentyFour": "24 horas"
          },
          "dateFormatOptions": {
            "DMY": "DD-MM-AAAA",
            "MDY": "MM-DD-AAAA"
          }
        },
        "accountInfo": {
          "organization": "Organización",
          "accountData": "Información de la cuenta",
          "accountName": "Nombre de la cuenta",
          "legalName": "Nombre de la empresa",
          "documentNumber": "CNPJ (solo Brasil)",
          "enterprisePlan": "Plan Empresarial",
          "members": "Miembros",
          "sessionTime": "Tiempo de sesión",
          "enabled": "Habilitado",
          "timeCaption": "Use textos como: 48h; 24h15m; 45m o 15m10s",
          "update": "Actualizar",
          "failedTime": "Valor inválido o menor de 1m",
          "successChange": "Actualización realizada con éxito",
          "failedChange": "Error al actualizar la información"
        },
        "members": {
          "searchPlaceholder": "Buscar por nombre o correo electrónico",
          "noSearchResults": "Sin coincidencias con las palabras <strong>${searchTerm}</strong>",
          "addUserButton": "Agregar nuevo miembro",
          "waitingRegistration": "Esperando registro",
          "resendInvitation": "Reenviar invitación",
          "maxOfMembersReached": "Esta cuenta ha alcanzado el límite máximo de miembros",
          "acquireMembers": "Adquirir miembros",
          "successfulMemberCreated": "Miembro agregado. Instrucciones de acceso enviadas al correo electrónico registrado.",
          "failedMemberCreated": "Error. Intente de nuevo.",
          "successfulResentInvitation": "Invitación enviada a <strong>${email}</strong>",
          "failedResentInvitation": "Error. Intente de nuevo.",
          "successfulMemberRemoved": "${name} (${email}) eliminado",
          "successfulMemberWithoutNameRemoved": "${email} eliminado",
          "failedMemberRemoved": "Error. Intente de nuevo.",
          "successfulMemberUpdated": "Miembro actualizado",
          "successfulFailedUpdated": "Error. Intente de nuevo.",
          "memberContextMenu": {
            "delete": "Eliminar",
            "edit": "Editar"
          },
          "removeMemberModal": {
            "title": "Eliminar miembro",
            "content": "¿Está seguro de que desea eliminar a <strong>${name}</strong> de la cuenta <strong>${accountName}</strong>?",
            "cancelButton": "Cancelar",
            "okButton": "Eliminar"
          }
        },
        "userProfile": {
          "personalInfo": "Información personal",
          "avatarLabel": "Foto",
          "nameLabel": "Nombre",
          "emailLabel": "Correo electrónico",
          "industryLabel": "Industria",
          "passwordLabel": "Contraseña",
          "changePasswordButton": "Actualizar contraseña",
          "updateButtonLabel": "Actualizar",
          "successfulUpdate": "Información actualizada",
          "failedUpdate": "Error. Intente de nuevo.",
          "industryOptions": {
            "construction": "Construcción",
            "mining": "Minería",
            "agriculture": "Agricultura",
            "droneOperator": "Operador de drones",
            "environment": "Medio ambiente",
            "consulting": "Consultoría"
          }
        },
        "usage": {
          "usageReportHeader": "Maply: Informe de Consumo",
          "processingsBox": {
            "title": "Procesamientos"
          },
          "imagesUsageBox": {
            "title": "Imágenes",
            "usage": "Cuota de Procesamiento",
            "images": "Imágenes",
            "balance": "Saldo",
            "storage": "Almacenamiento total",
            "helpText": ""
          },
          "historicBox": {
            "title": "Registros",
            "pdfDownload": "Descargar PDF",
            "csvDownload": "Descargar CSV",
            "loadingMessage": "Cargando, por favor espere...",
            "emptyMessage": "No se encontraron registros",
            "table": {
              "date": "Fecha",
              "debit": "Débito",
              "credit": "Crédito",
              "description": "Descripción",
              "filter": "Filtro",
              "processing": "Procesamientos"
            },
            "descriptionTranslation": {
              "processingDescription": "Débito de vuelo",
              "creditDescription": "Crédito mensual de imagen",
              "migrationDescription": "Migración de crédito",
              "extraCreditDescription": "Crédito adicional de imagen"
            }
          }
        },
        "processings": {
          "emptyMessage": "No se encontró ningún procesamiento",
          "filterButtonLabel": "Filtrar procesamientos",
          "processingFilter": {
            "from": "Desde",
            "to": "Hasta",
            "removeFilter": "Eliminar filtro",
            "filter": "Filtrar"
          }
        }
      }
    },
    "login": {
      "email": {
        "placeholder": "Correo electrónico",
        "required": "¡Por favor, introduzca su correo electrónico!"
      },
      "password": {
        "placeholder": "Contraseña",
        "required": "¡Por favor, introduzca su contraseña!"
      },
      "submit": "Iniciar sesión",
      "forgotPassword": "¿Olvidó su contraseña?",
      "register": "Registrarse"
    },
    "menu": {
      "account": {
        "logout": "Salir"
      }
    },
    "flights": {
      "common": {
        "newTitle": "Misión sin título",
        "rename": "Renombrar",
        "successfulRename": "Misión renombrada.",
        "actionFailed": "Acción no autorizada.",
        "failedRename": "Error al renombrar misión"
      },
      "new": {
        "pagination": {
          "nextTooltip": "Siguiente paso",
          "previousTooltip": "Paso anterior",
          "okTooltip": "Confirmar cambios"
        }
      },
      "list": {
        "card": {
          "newFlight": "Misión sin título",
          "excluded": "Misión Eliminada",
          "excludedFailed": "Error al eliminar misión",
          "renamed": "Misión renombrada",
          "renamedFailed": "Error al renombrar misión",
          "readyToFly": "Listo para volar",
          "incomplete": "Misión incompleta",
          "complete": "Misión completa",
          "lastUpdate": "Actualizado:",
          "contextMenuOptions": {
            "rename": "Renombrar",
            "delete": "Eliminar"
          },
          "deleteModal": {
            "title": "Eliminar Misión",
            "content": "¿Está seguro de que desea eliminar <strong>${name}</strong>? El planificador de misión se eliminará junto con ella.",
            "okButton": "Eliminar",
            "cancelButton": "Cancelar"
          }
        },
        "headerTitle": "Misiones",
        "new": "Nueva Misión",
        "notAuthorized": "Acción no autorizada.",
        "failed": "Hubo un problema al ejecutar la acción."
      },
      "steps": {
        "startFlightStep": {
          "title": "Nueva misión",
          "name": "Nombre de la misión",
          "namePlaceholder": "Misión sin título",
          "draw": "Dibujar un área",
          "kml": "Importar KML",
          "error": "KML inválido, utilice solo KML de tipo Polígono.",
          "noPlacemark": "KML: El archivo no contiene geometrías válidas para importar",
          "notKML": "KML: El archivo no es un KML válido, verifique el formato."
        },
        "locationFlightStep": {
          "title": "Definir ubicación de la misión",
          "location": "Definir ubicación de la misión",
          "locationPlaceholder": "Buscar ubicación...",
          "description": "Encuentre el área que será mapeada."
        },
        "drawFlightStep": {
          "title": "Dibujar área",
          "description": "Dibujar área de mapeo."
        }
      },
      "view": {
        "title": "Misión",
        "basic": "Básico",
        "advanced": "Avanzado",
        "height": "Altitud",
        "model": "Modelo de Dron",
        "direction": "Dirección de Vuelo",
        "stats": "Estadísticas de Vuelo",
        "frontal": "Superposición Frontal",
        "lateral": "Superposición Lateral",
        "manual": "Usar configuración manual de cámara.",
        "resolution": "Resolución",
        "area": "Área",
        "images": "Imágenes",
        "duration": "Duración",
        "distance": "Distancia Total",
        "batteries": "Baterías Requeridas"
      }
    },
    "processings": {
      "common": {
        "createdBy": "Creado por:",
        "images": "Imágenes",
        "rename": "Renombrar",
        "info": "Información",
        "defaultProcessingName": "Vuelo sin título",
        "successfulDelete": "Vuelo eliminado",
        "failedDelete": "Error. Intente de nuevo.",
        "successfulRename": "Nombre del vuelo cambiado",
        "failedRename": "Error. Intente de nuevo."
      },
      "new": {
        "common": {
          "notAuthorized": "Acción no autorizada.",
          "failedImport": "Hubo un problema al importar un nuevo vuelo.",
          "leaveRouteDescription": "Todos sus datos y cambios realizados hasta ahora se perderán.",
          "insufficientBalanceModal": {
            "title": "Saldo Insuficiente",
            "content": "Contacte al administrador para obtener más créditos. Si desea subir las imágenes sin procesar, haga clic en el botón continuar.",
            "okButton": "Continuar",
            "cancelButton": "Cancelar"
          }
        },
        "components": {
          "selectedImportFilesStatus": {
            "description": "archivos encontrados"
          },
          "selectedFilesStatus": {
            "description": "imágenes encontradas"
          },
          "pagination": {
            "nextTooltip": "Siguiente Paso",
            "previousTooltip": "Paso Anterior",
            "okTooltip": "Confirmar cambios"
          },
          "jumpStepLink": {
            "label": "Saltar Paso"
          }
        },
        "steps": {
          "projects": {
            "title": "Vincular un proyecto",
            "description": "Seleccione el proyecto en el cual publicará este procesamiento, o cree un nuevo proyecto.",
            "selectProjectDropdown": {
              "placeholder": "Seleccionar proyecto o crear..."
            },
            "jumpStepLink": {
              "label": "Saltar Paso",
              "tooltip": "Las comparaciones entre vuelos solo se mostrarán a través de un proyecto."
            }
          },
          "importFiles": {
            "title": "Vuelo Pre-Procesado",
            "description": "Seleccione los archivos que se importarán y se usarán para el procesamiento de mapas.",
            "orthomosaic": {
              "label": "Seleccione el archivo de ortomosaico",
              "placeholder": "Seleccionar un archivo...",
              "help": "Ortomosaico en formato TIF o TIFF"
            },
            "dsm": {
              "label": "Seleccione el archivo de Modelo de Superficie Digital (DSM)",
              "placeholder": "Seleccionar un archivo...",
              "help": "DSM en formato TIF o TIFF"
            },
            "dtm": {
              "label": "Seleccione el archivo de Modelo de Terreno Digital (DTM)",
              "placeholder": "Seleccionar un archivo...",
              "help": "DTM en formato TIF o TIFF"
            },
            "pointCloud": {
              "label": "Seleccione el archivo de nube de puntos",
              "placeholder": "Seleccionar un archivo...",
              "help": "Nube de puntos en formato LAS"
            }
          },
          "selectFiles": {
            "title": "Seleccione las imágenes",
            "description": "Seleccione en la sección a continuación qué imágenes se usarán en el procesamiento de mapas.",
            "dragDrop": {
              "dropHere": "Arrastre y suelte o haga clic para seleccionar imágenes...",
              "loading": "Analizando las imágenes"
            }
          },
          "validateDataset": {
            "title": "Validar Conjunto de Datos",
            "description": "Haga clic a continuación para verificar si su conjunto de datos está listo para ser procesado.",
            "buttonTitle": "Validar Conjunto de Datos",
            "validationSteps": {
              "step1": {
                "title": "Cantidad de Imágenes",
                "description1": "Mínimo de ${minImages} y máximo de ${maxImages} imágenes.",
                "description2": "Si desea cargar más imágenes en un solo vuelo, contáctenos en ",
                "emailLink": "contacto@maply.io"
              },
              "step2": {
                "title": "Información Geográfica",
                "description": "Las imágenes deben contener datos de georreferenciación."
              },
              "step3": {
                "title": "Tamaño del Mapeo",
                "description": "Área de mapeo entre 0.1 y 3,000 ha."
              },
              "step4": {
                "title": "Imágenes sin información de orientación.",
                "description": "Todas las imágenes deben contener información de orientación de la cámara."
              }
            },
            "validationImportSteps": {
              "step1": {
                "title": "Sistema de Coordenadas (EPSG)",
                "description": "Todos los archivos deben estar en el mismo sistema de coordenadas (EPSG)."
              },
              "step2": {
                "title": "Área",
                "description": "Todos los archivos deben tener el mismo límite de área."
              },
              "step3": {
                "title": "Formato Correcto de Archivo",
                "description": "Todos los archivos deben cumplir con lo siguiente:<br>Ortomosaico: 4 bandas (RGB + Alfa)<br>DSM y DTM: 1 banda (float32) con valor NODATA definido<br>LAS: Versión mínima 1.2 y Formato de Datos de Puntos ID 2, 3, 7 o 8"
              }
            },
            "validatedMessage": "Imágenes validadas exitosamente",
            "showListMessage": "Haga clic en el icono de información para ver la lista.",
            "showListOfFilesModal": {
              "title": "Lista de imágenes sin información de orientación",
              "content": "Las imágenes sin orientación son: ${list}",
              "okButton": "Ok"
            }
          },
          "controlPoints": {
            "title": "Puntos de Control Terrestres",
            "description": "Seleccione el archivo de puntos de control terrestre (archivo.txt).",
            "verticalValues": {
              "constShift": "Altura del geoide sobre el elipsoide Bessel1 1841 (m)",
              "noConv": "Arbitrario"
            },
            "fileSelected": {
              "fileFormatTitle": "Formato de archivo",
              "crsTitle": "Sistema de Coordenadas",
              "crsHorizontalTitle": "Horizontal",
              "crsHorizontalPlaceholder": "Seleccione el CRS horizontal...",
              "crsVerticalTitle": "Vertical",
              "crsVerticalPlaceholder": "Seleccione el CRS vertical...",
              "crsCustomAltitudeTitle": "Altura",
              "validateButtonTitle": "Validar GCPs",
              "gcpsFoundInFilePlural": "puntos de control terrestre encontrados en el archivo",
              "gcpsFoundInFileSing": "punto de control terrestre encontrado en el archivo",
              "someGcpsIgnoredPlural": "puntos de control terrestre ignorados porque están fuera de los límites de la imagen",
              "someGcpsIgnoredSing": "punto de control terrestre ignorado porque está fuera de los límites de la imagen",
              "someGcpsDuplicatedPlural": "puntos de control terrestre ignorados debido a coordenadas duplicadas",
              "someGcpsDuplicatedSing": "punto de control terrestre ignorado debido a coordenada duplicada",
              "gcpsFoundDescription": "puntos de control terrestre utilizados",
              "gcpsNotFoundDescription": "Verifique su archivo.",
              "gcpsErrorMessage": "Hemos encontrado un problema al validar su archivo de GCP.",
              "gcpsErrorMessages": {
                "DUPLICATE_LABELS": "Hay nombres de GCP duplicados en el archivo. Verifique el archivo e intente nuevamente",
                "DISTANCE": "La distancia entre los GCP y las imágenes es mayor de 10 km. Verifique el archivo e intente nuevamente",
                "FILE_STRUCTURE": "El archivo es inválido. Intente nuevamente.",
                "COORDINATE": "Este archivo tiene coordenadas inválidas. Verifique e intente nuevamente.",
                "ALTITUDE": "Este archivo tiene valores de elevación inválidos. Verifique e intente nuevamente."
              },
              "previousCRSNotification": {
                "title": "Atención",
                "description1": "El último procesamiento para este proyecto ",
                "description2": "usó el sistema de coordenadas"
              }
            },
            "fileNotSelected": {
              "title": "Archivo de GCP",
              "description1": "Debe generar un archivo de texto en cualquier editor de texto y guardarlo como .txt con las coordenadas de los GCP para ser insertadas en su procesamiento.",
              "linkDescription": "Descargar archivo de ejemplo de GCP",
              "jumpModal": {
                "title": "¿Está seguro de que desea saltar este paso?",
                "description": "Si omite este paso, su procesamiento no estará georreferenciado.",
                "okButton": "Sí",
                "cancelButton": "Cancelar"
              }
            }
          },
          "processingDetails": {
            "title": "Detalles de Procesamiento",
            "processingNameLabel": "Nombre del Procesamiento",
            "processingNameDefaultValue": "Procesamiento sin título",
            "CaptureDateLabel": "Fecha de Captura",
            "lastFlightNameDescription": "Último procesamiento del proyecto:"
          },
          "processingResume": {
            "title": "Resumen de Procesamiento",
            "linkedProject": "Proyecto Vinculado",
            "processing": "Procesamiento",
            "groundControlPoints": "Puntos de Control Terrestres",
            "startProcessingAfterUpload": "Iniciar procesamiento tan pronto como se complete la carga.",
            "maplySupport": "Soporte de Maply",
            "commentsPlaceholder": "Deje un comentario aquí para el equipo de soporte de Maply...",
            "images": "imágenes",
            "calculated_images": "imágenes estimadas",
            "gigapixels": "gigapíxeles",
            "points": "puntos",
            "files": "Archivos",
            "orthoFile": "Ortomosaico",
            "dsmFile": "DSM",
            "dtmFile": "DTM",
            "ptFile": "Nube de Puntos"
          }
        }
      },
      "upload": {
        "common": {
          "header": "Nuevo Vuelo",
          "headerTitle": "Lista de Imágenes"
        },
        "components": {
          "statusBar": {
            "pausedTitle": "Carga pausada en ${progress}%",
            "loadingTitle": "Cargando imágenes - ${progress}%",
            "loadingImages": "${partial} de ${total} imágenes cargadas",
            "loadingBytes": "${partial}MB de ${total}MB"
          },
          "resumeModal": {
            "title": "Reanudar la carga",
            "description": "Para continuar su proceso de carga, haga clic abajo y seleccione sus imágenes nuevamente.",
            "dragDrop": {
              "dropHere": "Arrastre y suelte su conjunto de datos o haga clic para seleccionar sus imágenes… ",
              "loading": "Analizando las imágenes"
            },
            "buttonSuccessTitle": "Continuar carga",
            "buttonFailedTitle": "Cargar nuevamente",
            "validMessage": "Imágenes validadas",
            "invalidMessage": "Las imágenes no coinciden"
          }
        },
        "header": {
          "images": "imágenes",
          "options": {
            "All": "Todas",
            "Loaded": "Cargadas",
            "Loading": "Cargando"
          }
        }
      },
      "list": {
        "components": {
          "processingStatus": {
            "statusText": {
              "uploading": "Cargando - ${progress}%",
              "uploadPaused": "Carga pausada - ${progress}%",
              "uploadCanceled": "Carga cancelada",
              "uploadFinished": "Carga finalizada",
              "processing": "Procesando",
              "declareFailure": "Procesamiento fallido",
              "processingDone": "Procesado",
              "unknown": "Estado indefinido"
            }
          },
          "processingCard": {
            "contextMenuOptions": {
              "rename": "Renombrar",
              "delete": "Eliminar",
              "info": "Información"
            },
            "deleteModal": {
              "title": "Eliminar procesamiento",
              "content": "¿Está seguro de que desea eliminar <strong>${name}</strong>?",
              "cancelButton": "Cancelar",
              "okButton": "Eliminar"
            }
          },
          "emptyProcessingList": {
            "noProcessings": "No hay procesamientos",
            "description": "Haga clic en <strong>Subir Imágenes</strong> para importar un conjunto de datos.",
            "buttonLabel": "Subir Imágenes"
          },
          "emptyFlightList": {
            "noFlights": "Sin Misión",
            "description": "Haga clic en <strong>Nueva Misión</strong> para dibujar una misión de mapeo",
            "buttonLabel": "Nueva Misión"
          },
          "detailInfoModal": {
            "header": "Información detallada del vuelo",
            "location": "Ubicación",
            "area": "Área",
            "numImages": "Número de imágenes",
            "resolution": "Resolución",
            "user": "Usuario",
            "accuracyReport": "Informe de Precisión",
            "showReport": "Ver Informe",
            "notProcessed": "No procesado"
          }
        },
        "common": {
          "headerTitle": "Procesamiento",
          "importLink": "Importar",
          "importMenu": "Importar",
          "importImages": "Importar Imágenes",
          "importPreProcessed": "Importar Preprocesado"
        }
      },
      "view": {
        "pages": {
          "exports": {
            "title": "Exportaciones"
          },
          "processing": {
            "title": "Capas"
          }
        },
        "common": {
          "headerTitle": "Capas",
          "layerType": {
            "orthomosaic": "Mapa 2D",
            "dsm": "Modelo de Elevación",
            "dtm": "Modelo de Elevación",
            "slope": "Gradiente de Pendiente",
            "mapping_images": "Imágenes de Mapeo",
            "inspection_images": "Imágenes de Inspección",
            "point_cloud": "Nube de Puntos",
            "contour_lines": "Líneas de Contorno",
            "mesh_3d": "Modelo 3D",
            "gcps": "Capa de GCPs",
            "design_layers": "Capas de Diseño"
          },
          "elevationLayerType": {
            "dsm": "Modelo de Superficie Digital",
            "dtm": "Modelo de Terreno Digital"
          },
          "insufficientBalanceModal": {
            "title": "Saldo insuficiente",
            "content": "Para procesar el conjunto de datos, por favor contacte al administrador",
            "okButton": "Ok"
          },
          "startProcessingModal": {
            "title": "Iniciar procesamiento",
            "content": "¿Confirma el procesamiento?",
            "okButton": "Sí",
            "cancelButton": "Cancelar"
          },
          "startProcessingButtonLabel": "Iniciar procesamiento",
          "toastMessage": "Su carga ha finalizado, esperando para iniciar el procesamiento.",
          "successfulUpdate": "El procesamiento ha comenzado",
          "failedUpdate": "Error. Intente nuevamente."
        },
        "components": {
          "classificationLayerOptions": {
            "title": "Clases de Puntos LIDAR Estándar ASPRS",
            "items": {
              "not_classified": "Sin clasificación",
              "terrain": "Terreno",
              "low_vegetation": "Vegetación Baja",
              "medium_vegetation": "Vegetación Media",
              "high_vegetation": "Vegetación Alta",
              "building": "Construcción",
              "low_noise": "Ruido Bajo",
              "high_noise": "Ruido Alto",
              "water": "Agua"
            }
          },
          "elevationLayerOptions": {
            "modelType": "Tipo de modelo"
          },
          "histogramLayerOptions": {
            "histogram": "Histograma"
          },
          "colormapLayerOptions": {
            "colors": "Gradiente"
          },
          "pointCloudLayerOptions": {
            "pointDensity": "Densidad de puntos (miles)",
            "pointSize": "Tamaño del punto",
            "highQuality": "Alta Resolución",
            "advancedSettings": "Configuración avanzada",
            "filters": {
              "title": "Filtros",
              "items": {
                "rgb": "RGB",
                "elevation": "Elevaciones",
                "rgbElevation": "RGB / Elevaciones",
                "classification": "Clasificaciones"
              }
            }
          },
          "baseLayerOptions": {
            "opacity": "Opacidad"
          },
          "gcpLayerOptions": {
            "download": "Descargar Puntos de Control"
          },
          "meshLayerOptions": {
            "opacity": "Opacidad",
            "highResolution": "Alta Resolución"
          },
          "contourLayerOptions": {
            "opacity": "Opacidad",
            "range": "Límites",
            "interval": "Intervalo (metros)",
            "start": "Elevación Inicial",
            "step": "Intervalo",
            "tags": "Etiquetas",
            "show": "Mostrar Valores de Elevación"
          },
          "imageLayerOptions": {
            "images": "imágenes",
            "add": "Agregar imágenes"
          },
          "paginationBar": {
            "prev": "Anterior",
            "next": "Siguiente",
            "of": "de"
          }
        }
      }
    },
    "projects": {
      "common": {
        "defaultProjectName": "Proyecto sin título",
        "successfulRename": "Proyecto renombrado",
        "failedRename": "Error. Intente de nuevo",
        "failedAccessNotPermitted": "Acceso no permitido o no encontrado",
        "rename": "Renombrar",
        "menu": {
          "project": "Proyecto",
          "annotations": "Anotar",
          "compare": "Comparar",
          "export": "Exportar",
          "reports": "Informes",
          "settings": "Configuraciones"
        }
      },
      "components": {
        "projectForm": {
          "projectNamePlaceholder": "Escriba el nombre del proyecto...",
          "defaultProjectName": "Proyecto sin título",
          "noProcessings": "No hay procesamientos",
          "selectProjectLabel": "Seleccione el procesamiento para el proyecto.",
          "searchPlaceholder": "Buscar procesamientos"
        }
      },
      "new": {
        "leaveRouteDescription": "No se creará un nuevo proyecto.",
        "successfulCreate": "Proyecto ${name} creado",
        "failedCreate": "Error. Intente de nuevo."
      },
      "list": {
        "common": {
          "headerTitle": "Proyectos",
          "defaultProjectName": "Proyecto sin título",
          "successfulDelete": "Proyecto eliminado",
          "failedDelete": "Error. Intente de nuevo.",
          "successfulRename": "Proyecto renombrado",
          "failedRename": "Error. Intente de nuevo.",
          "successfulMove": "Proyecto movido a carpeta",
          "failedMove": "Error. Intente de nuevo.",
          "folder": {
            "successfulDelete": "Carpeta eliminada",
            "failedDelete": "Hubo un problema al eliminar la carpeta. Intente de nuevo",
            "successfulRename": "Proyecto renombrado",
            "failedRename": "Hubo un problema al renombrar la carpeta. Intente de nuevo",
            "root": "Raíz"
          }
        },
        "components": {
          "folderCard": {
            "by": "por",
            "contextMenuOptions": {
              "rename": "Renombrar",
              "delete": "Eliminar"
            },
            "deleteModal": {
              "title": "Eliminar carpeta de proyecto",
              "content": "¿Está seguro de que desea eliminar la carpeta <strong>${name}</strong>? Todos los proyectos y sus anotaciones, comentarios y superposiciones se eliminarán junto con esta carpeta. Los vuelos no se verán afectados.",
              "okButton": "Eliminar",
              "cancelButton": "Cancelar"
            }
          },
          "projectCard": {
            "lastUpdate": "Modificado:",
            "contextMenuOptions": {
              "rename": "Renombrar",
              "delete": "Eliminar",
              "moveToFolder": "Mover a carpeta..."
            },
            "processingsTag": {
              "manyProcessings": "${amount} vuelos",
              "oneProcessing": "1 vuelo",
              "noProcessing": "Sin vuelos"
            },
            "deleteModal": {
              "title": "Eliminar proyecto",
              "content": "¿Está seguro de que desea eliminar <strong>${name}</strong>? Todas las anotaciones, comentarios y capas de diseño se eliminarán. Los procesamientos no se eliminarán.",
              "okButton": "Eliminar",
              "cancelButton": "Cancelar"
            }
          },
          "emptyProjectList": {
            "noProjects": "Sin proyecto",
            "description": "Haga clic en <strong>Nuevo proyecto</strong> para comenzar",
            "buttonLabel": "Nuevo proyecto"
          }
        },
        "newProject": "Nuevo proyecto",
        "searchProjectPlaceholder": "Buscar proyecto",
        "newFolderPlaceholder": "Escriba el nombre de la carpeta",
        "newFolderDefaultValue": "Carpeta sin título"
      },
      "view": {
        "components": {
          "members": {
            "addMemberModal": {
              "title": "Agregar Miembro",
              "content": "Busque y seleccione los miembros que desea agregar",
              "placeholder": "Seleccione los miembros...",
              "okButton": "Ok",
              "cancelButton": "Cancelar"
            },
            "removeModalAnnotation": {
              "title": "Eliminar usuario",
              "content": "¿Está seguro de que desea eliminar a este usuario de esta anotación?",
              "okButton": "Sí",
              "cancelButton": "Cancelar"
            },
            "roleTypes": {
              "owner": {
                "title": "Propietario",
                "description": "Control total"
              },
              "reader": {
                "title": "Lector",
                "description": "Puede ver y comentar"
              },
              "writer": {
                "title": "Editor",
                "description": "Puede editar e invitar a usuarios"
              },
              "delete": {
                "title": "Eliminar",
                "description": "Quitar permisos de usuario"
              }
            }
          },
          "comment": {
            "attachment": "Archivo adjunto",
            "format": "FORMATO:",
            "you": "Tú",
            "erased": "eliminaste este mensaje",
            "exclude": "Eliminar comentario",
            "download": "Descargar imagen",
            "uploadbar": {
              "file": "Archivo",
              "image": "Imagen"
            },
            "removeModal": {
              "title": "Eliminación de Comentario",
              "content": "¿Está seguro de que desea eliminar el comentario?",
              "okButton": "Sí",
              "cancelButton": "Cancelar"
            }
          },
          "comments": {
            "uploadFailedModal": {
              "title": "Carga fallida",
              "content": "La carga falló, por favor intente de nuevo.",
              "okButton": "Ok"
            }
          },
          "annotation": {
            "subheader": {
              "analysis": "Análisis",
              "comments": "Comentarios",
              "members": "Miembros"
            },
            "contents": {
              "analysis": {
                "copied": "Copiado al portapapeles",
                "point": {
                  "coordinate": "Coordenadas",
                  "elevation": "Elevación",
                  "results": "Resultados",
                  "height": "Altura",
                  "surface": "Superficie para cálculo",
                  "northing": "Coordenada Norte",
                  "easting": "Coordenada Este",
                  "latitude": "Latitud",
                  "longitude": "Longitud"
                },
                "line": {
                  "length": "Longitud",
                  "surface": "Superficie",
                  "heightDiff": "Diferencia de altura",
                  "diff": "Diferencia",
                  "vertex": "Vértice",
                  "profile": "Sección transversal",
                  "graph": "Gráfico",
                  "graphActivated": "GRÁFICO DE BARRA INFERIOR ACTIVADO",
                  "addComparison": "Agregar comparación",
                  "selectSurface": "Seleccione la superficie para comparar",
                  "select": "Seleccionar",
                  "cancel": "Cancelar",
                  "surfaceHeader": "Superficie calculada",
                  "results": "Resultados"
                },
                "axis": {
                  "title": "Generar Secciones Transversales",
                  "axis": "Eje",
                  "initial": "Sección Inicial",
                  "final": "Sección Final",
                  "distance": "Distancia",
                  "width": "Ancho de las Secciones",
                  "generate": "Generar Secciones Transversales",
                  "project": "Proyecto de Referencia",
                  "primitive": "NT: Primitive Project",
                  "initialError": {
                    "title": "Advertencia: Los Parámetros Insertados Contienen Errores",
                    "content": "Existen problemas con los parámetros proporcionados. Revise y corrija los siguientes elementos: <br><br> <strong>Estación Inicial</strong>: Asegúrese de que el valor proporcionado esté dentro de los límites del eje."
                  },
                  "finalError": {
                    "title": "Advertencia: Los Parámetros Insertados Contienen Errores",
                    "content": "Existen problemas con los parámetros proporcionados. Revise y corrija los siguientes elementos: <br><br> <strong>Estación Final</strong>: Asegúrese de que la estación final sea mayor que la inicial y esté dentro de los límites del eje."
                  },
                  "boundsError": {
                    "title": "Advertencia: Los Parámetros Insertados Contienen Errores",
                    "content": "Existen problemas con los parámetros proporcionados. Revise y corrija los siguientes elementos: <br><br> <strong>Ancho Total de la Sección</strong>: El Ancho de la Sección no debe extenderse fuera del área mapeada."
                  },
                  "finishMessage": {
                    "title": "Su solicitud está siendo procesada",
                    "content": "Su solicitud para Generar Secciones Transversales ha comenzado y en unos momentos el informe estará disponible para visualizar.",
                    "okButton": "Sí"
                  }
                },
                "polygon": {
                  "area": "Área",
                  "planeArea": "Área plana",
                  "baseArea": "Área base",
                  "volumeHeader": "Volúmenes y superficies",
                  "surface": "Superficie para cálculo",
                  "type": "Tipo de cálculo",
                  "elevationInput": "Ingrese un valor de altura",
                  "calculate": "Calcular",
                  "surfaceSubheader": "Superficie",
                  "surfaceArea": "Área de superficie",
                  "volume": "Volumen",
                  "cut": "Corte",
                  "fill": "Relleno",
                  "total": "Total",
                  "surfaceSelect": "Seleccione la superficie para comparar",
                  "select": "Seleccionar",
                  "cancel": "Cancelar",
                  "copied": "Copiado al portapapeles",
                  "generateReport": "Generar Informe",
                  "outOfBounds": "La anotación contiene vértices fuera del área mapeada",
                  "copyVolumeText": "Corte\t${cut} ${unit}\nRelleno\t${fill} ${unit}\nTotal\t${total} ${unit}",
                  "copyAreaText": "Área de Superficie\t${surface} ${unit}\nÁrea Base\t${base} ${unit}",
                  "calcTypes": {
                    "triangulated-delaunay": {
                      "title": "Triangulado (Delaunay)",
                      "description": "Las elevaciones de los vértices del polígono se triangulan para crear la base."
                    },
                    "triangulated-earcut": {
                      "title": "Triangulado (Earcut)",
                      "description": "Las elevaciones de los vértices del polígono se triangulan para crear la base."
                    },
                    "triangulated": {
                      "title": "Triangulado",
                      "description": "Las elevaciones de los vértices del polígono se triangulan para crear la base."
                    },
                    "plane": {
                      "title": "Plano",
                      "description": "La base se crea con la elevación constante definida por el usuario (plano)."
                    },
                    "comparative": {
                      "title": "Comparación entre Superficies",
                      "description": "La base de referencia es otra superficie, siendo otro procesamiento o superficie externa."
                    }
                  }
                }
              }
            },
            "headers": {
              "annotationHeader": {
                "createdAt": "creado en",
                "by": "por",
                "dropdown": {
                  "edit": "Editar",
                  "rename": "Renombrar",
                  "moveToFolder": "Mover a carpeta",
                  "color": "Color",
                  "goToAnnotation": "Ir a la anotación",
                  "generateReport": "Generar Informe",
                  "downloadAnnotation": "Descargar anotación",
                  "exportVolume": "Exportar volumen",
                  "transformAxis": "Generar Alineación Horizontal",
                  "copyToAnotherFlight": "Copiar a otro vuelo",
                  "remove": "Eliminar",
                  "kml": "KML (WGS 84)",
                  "geojson": "GEOJSON (WGS 84)",
                  "csv": "CSV (${crs})",
                  "dxf": "DXF (${crs})"
                },
                "volumeHeader": "Fecha;Nombre;Área(m2);Corte;Relleno",
                "csvHeader": "NOMBRE;X;Y;Z",
                "successfulRename": "Anotación renombrada",
                "failedRename": "Error. Intente de nuevo",
                "successfulRemove": "Anotación eliminada",
                "failedRemove": "Error.",
                "removeModal": {
                  "title": "Confirmación de eliminación de anotación",
                  "content": "¿Está seguro de que desea eliminar la anotación?",
                  "okButton": "Sí",
                  "cancelButton": "Cancelar"
                }
              }
            },
            "folderItem": {
              "by": "por"
            },
            "transform": {
              "subheader": "Para generar la alineación horizontal, es necesario proporcionar un punto de referencia del eje.",
              "generate": "Generar Alineación Horizontal",
              "point": "Punto de referencia:",
              "stake": "Referencia inicial:"
            }
          },
          "annotations": {
            "defaultAnnotationName": "Anotación sin título",
            "headers": {
              "mainHeader": {
                "filter": {
                  "marker": "Puntos",
                  "polyline": "Líneas",
                  "axis": "Ejes",
                  "polygon": "Polígonos"
                },
                "tooltips": {
                  "visible": "Visibilidad",
                  "textVisible": "Nombres",
                  "importKml": "Importar KML",
                  "newFolder": "Nueva carpeta",
                  "filter": "Filtrar",
                  "search": "Buscar",
                  "actions": "Acciones"
                }
              },
              "addMember": {
                "title": "Agregar miembro"
              },
              "subHeaderSelections": {
                "inactiveOption": "Función inactiva porque hay anotaciones creadas por otros que están seleccionadas.",
                "0selectedAnnotation": "Ninguna anotación seleccionada",
                "1selectedAnnotation": "1 anotación seleccionada",
                "selectedAnnotations": "${size} anotaciones seleccionadas"
              },
              "folderHeader": {
                "menu": {
                  "downloadFolder": "Descargar carpeta",
                  "exportVolumes": "Exportar Volúmenes",
                  "geojson": "GEOJSON (WGS 84)",
                  "csv": "CSV (WGS 84)",
                  "kml": "KML (WGS 84)",
                  "remove": "Eliminar",
                  "rename": "Renombrar"
                },
                "csvHeader": "NOMBRE;X;Y;Z",
                "volumeHeader": "Fecha;Nombre;Área(m2);Corte;Relleno",
                "failedVolumeDownload": "No se encontró ninguna anotación de volumen.",
                "successfulRename": "Carpeta renombrada",
                "failedRename": "Error.",
                "successfulRemove": "Carpeta eliminada",
                "failedRemove": "Error",
                "removeModal": {
                  "title": "Confirmación de eliminación de carpeta",
                  "content": "¿Está seguro de que desea eliminar la carpeta?<br>Se perderán todas las anotaciones, comentarios y archivos adjuntos.",
                  "okButton": "Sí",
                  "cancelButton": "Cancelar"
                }
              }
            },
            "emptyList": {
              "title": "Sin anotaciones",
              "description": "Para crear una nueva anotación, haga clic en la esquina superior derecha de la pantalla."
            },
            "emptyFolderList": {
              "title": "Carpeta vacía",
              "description": "Mueva o arrastre una anotación a esta carpeta, o revise su búsqueda"
            },
            "folderTabs": {
              "members": "Miembro",
              "annotations": "Anotaciones",
              "removeModalFolder": {
                "title": "Eliminar usuario",
                "content": "¿Está seguro de que desea eliminar el usuario seleccionado de esta carpeta?<br>Este usuario perderá acceso a todas las anotaciones en esta carpeta.<br>Nota: Todas las anotaciones creadas por este usuario serán movidas a la carpeta raíz.",
                "okButton": "Sí",
                "cancelButton": "Cancelar"
              }
            },
            "memberItem": {
            },
            "annotationItem": {
              "by": "por",
              "defaultAnnotationName": "Anotación sin título",
              "dropdown": {
                "edit": "Editar",
                "rename": "Renombrar",
                "moveToFolder": "Mover a carpeta",
                "color": "Color",
                "goToAnnotation": "Ir a la anotación",
                "generateReport": "Generar Informe",
                "downloadAnnotation": "Descargar anotación",
                "exportVolume": "Exportar Volumen",
                "transformAxis": "Generar Alineación Horizontal",
                "copyToAnotherFlight": "Copiar a otro vuelo",
                "remove": "Eliminar",
                "kml": "KML (WGS 84)",
                "geojson": "GEOJSON (WGS 84)",
                "csv": "CSV (${crs})",
                "dxf": "DXF (${crs})"
              },
              "volumeHeader": "Fecha;Nombre;Área(m2);Corte;Relleno",
              "csvHeader": "NOMBRE;X;Y;Z",
              "successfulRename": "Anotación renombrada",
              "failedRename": "Error",
              "successfulRemove": "Anotación eliminada",
              "failedRemove": "Error",
              "removeModal": {
                "title": "Confirmación",
                "content": "¿Desea eliminar la anotación?",
                "okButton": "Sí",
                "cancelButton": "Cancelar"
              }
            }
          },
          "wizard": {
            "chooseFlow": {
              "title": "Método de referencia",
              "flowOption1": {
                "label": "Marcar las coordenadas en el mapa",
                "description": "El punto de referencia se marcará visualmente en el mapa y en el PDF."
              },
              "flowOption2": {
                "label": "Introducir las coordenadas manualmente en la capa",
                "description": "El punto de referencia se introducirá manualmente de acuerdo con el sistema de coordenadas."
              }
            },
            "mapImageCompare": {
              "phase1Message": "Paso 1: Marque el primer punto de referencia en ambos mapas. Haga clic en \"Siguiente\"",
              "phase2Message": "Paso 2: Marque el segundo punto de referencia en ambos mapas. Haga clic en \"Siguiente\"",
              "markers": "Marcadores",
              "marker": "Marcador",
              "showInvalidPointsModal": {
                "title": "Marcaciones inválidas",
                "content": "Las coordenadas ingresadas son incorrectas, verifique el sistema de coordenadas y los valores.",
                "okButton": "Ok"
              },
              "showBoundsWarningModal": {
                "title": "Marcaciones incorrectas",
                "content": "Las coordenadas ingresadas están fuera de los límites del mapa, verifique si los valores son correctos",
                "okButton": "Ok"
              }
            }
          },
          "designLayers": {
            "title": "Capas de diseño",
            "buttonTitle": "Gestionar capas",
            "emptyMessage": "No se agregó ninguna capa",
            "opacityTitle": "Opacidad",
            "occluded": "Activar Oclusión",
            "color": "Color Base (LandXML)",
            "wireframe": "Activar Wireframe (LandXML)",
            "raycast": "Activar Detección de Coordenadas",
            "selectLayerTitle": "Seleccionar el archivo"
          },
          "designLayersWizard": {
            "title": "Capas de diseño",
            "closeButton": "Cerrar",
            "nextButton": "Siguiente",
            "prevButton": "Anterior",
            "cancelButton": "Cancelar",
            "sendButton": "Enviar a procesar",
            "successfulInsert": "Capa enviada a procesar",
            "failedInsert": "Error",
            "placeholderCrs": "Sistema de coordenadas"
          },
          "designLayersManager": {
            "title": "Gestionar capas",
            "createButtonLabel": "Incluir archivos",
            "newFolder": "Nueva Carpeta",
            "table": {
              "file": "Nombre del archivo",
              "createdBy": "Creado por",
              "createdAt": "Fecha",
              "format": "Formato",
              "status": "Estado",
              "emptyMessage": "No se agregó ningún archivo"
            },
            "status": {
              "requested": "Solicitado",
              "processing": "Procesando",
              "success": "Calibrado",
              "failed": "Fallido"
            },
            "fileType": {
              "xml": "XML",
              "csv": "CSV",
              "tiff": "TIFF",
              "pdf": "PDF",
              "x-step": "IFC"
            },
            "itemOptions": {
              "recalibrate": "Recalibrar",
              "rename": "Renombrar",
              "download": "Descargar",
              "remove": "Eliminar"
            },
            "successfulRename": "Archivo renombrado.",
            "successfulRemove": "Archivo eliminado.",
            "failedRename": "Error, intente nuevamente.",
            "failedRemove": "Error, intente nuevamente.",
            "failedInsert": "Error al enviar la capa a ser procesada",
            "landXMLModal": {
              "title": "Error de validación en archivo .xml",
              "cancelButton": "Cancelar carga",
              "multipleSurfaces": "El archivo contiene múltiples superficies, vuelva a cargar el archivo simplificado."
            },
            "csvModal": {
              "title": "Error de validación en archivo .csv",
              "headerBody": "El archivo contiene las siguientes líneas con errores de validación:",
              "okButton": "Cargar líneas válidas",
              "cancelButton": "Cancelar carga",
              "count": "Línea inválida, falta información requerida.",
              "coordinate": "Las coordenadas de geolocalización deben ser un número en metros.",
              "azimuth": "El ángulo de azimut debe ser un número, entre 0 y 360.",
              "polar": "El ángulo polar debe ser un número, entre -90 y 90.",
              "diameter": "El diámetro debe ser un número en metros.",
              "length": "La profundidad debe ser un número en metros, mayor de 1 metro.",
              "epsg": "El código EPSG debe ser un número válido.",
              "bounds": "Coordenada fuera del área mapeada."
            },
            "errorModal": {
              "title": "Archivo inválido",
              "content": "Por favor, verifique el archivo e intente nuevamente.",
              "okButton": "Ok"
            },
            "newFolderModal": {
              "title": "Nueva carpeta",
              "content": "Escriba el nombre de la nueva carpeta",
              "placeholder": "Escriba aquí...",
              "okButton": "OK",
              "cancelButton": "Cancelar"
            },
            "designLayersBasePointModal": {
              "title": "Ingresar coordenadas del punto base",
              "content": "Seleccione el sistema de coordenadas del punto base e ingréselo a continuación:",
              "body": "Ingrese las coordenadas del punto base en el sistema de coordenadas del proyecto:",
              "cancelButton": "Cancelar",
              "okButton": "OK"
            },
            "designLayersSentModal": {
              "title": "Enviado a procesar",
              "content": "El archivo fue enviado a procesar, pronto la capa estará disponible.",
              "okButton": "Ok"
            },
            "removeModal": {
              "title": "Eliminar archivo",
              "content": "¿Está seguro de que desea eliminar el archivo seleccionado?",
              "okButton": "Eliminar",
              "cancelButton": "Cancelar"
            },
            "removePermissionModal": {
              "title": "Acción no permitida",
              "content": "Solo el usuario que cargó la capa puede eliminarla. Por favor, contacte a <strong>${userName}</strong>",
              "okButton": "Ok"
            }
          }
        },
        "footers": {
          "annotation": {
            "editAnnotation": "Editar Anotación",
            "cancelButton": "Cancelar",
            "saveButton": "Guardar"
          },
          "comparison": {
            "leftProcessing": "Vuelo a la izquierda",
            "rightProcessing": "Vuelo a la derecha"
          },
          "processingSelection": {
          },
          "exports": {
            "importKml": "Importar KML",
            "exportKml": "Exportar KML",
            "selectPlaceholder": "Seleccionar o Crear Nuevo...",
            "remove": "Eliminar",
            "save": "Guardar",
            "successfulCropAreaCreated": "Área creada.",
            "failedCropAreaCreated": "Error.",
            "successfulCropAreaUpdated": "Área actualizada.",
            "failedCropAreaUpdated": "Error.",
            "successfulCropAreaDeleted": "Área eliminada.",
            "failedCropAreaDeleted": "Error.",
            "successfulImportedKml": "El KML fue importado.",
            "failedImportedKml": "Error. Por favor, revise el archivo KML.",
            "failedOutsideBounds": "Error. El KML está fuera de los límites del mapa."
          }
        },
        "pages": {
          "project": {
            "title": "Proyecto"
          },
          "annotations": {
            "title": "Anotaciones y mediciones",
            "successfulRemove": "Anotaciones eliminadas",
            "successCopy": "Anotación copiada",
            "successCreate": "Anotación importada",
            "failedRemove": "Hubo un error al eliminar las anotaciones",
            "failedExceedLimit": "Error de importación de KML: el archivo contiene más de ${count} anotaciones, límite de anotaciones.",
            "newFolderPlaceholder": "Escriba el nombre de la carpeta",
            "newFolderDefaultValue": "Carpeta sin título",
            "failedOutsideBounds": "Marcadores no importados:",
            "removeModal": {
              "title": "Confirmación de Eliminación",
              "content": "¿Está seguro de que desea eliminar las anotaciones seleccionadas?",
              "okButton": "Sí",
              "cancelButton": "Cancelar"
            },
            "kml": {
              "noPlacemark": "KML: El archivo no contiene geometrías válidas",
              "notKML": "KML: El archivo no es reconocido como KML válido, revise el formato"
            }
          },
          "comparisons": {
            "title": "Comparaciones de Procesamiento"
          },
          "exports": {
            "title": "Exportaciones",
            "sentModal": {
              "title": "El archivo está siendo procesado",
              "content": "Maply enviará un correo electrónico en cuanto el procesamiento esté listo.",
              "okButton": "Ok"
            },
            "sentModalBigTiff": {
              "title": "El archivo está siendo procesado",
              "content": "Maply enviará un correo electrónico en cuanto el procesamiento esté listo!<br/><br/><strong>Atención:</strong> Debido a su tamaño, este archivo puede no ser compatible con algunos programas de terceros. Por favor, seleccione un GSD mayor.",
              "okButton": "Ok"
            },
            "errorJpeg": {
              "title": "Tamaño JPEG Excedido",
              "content": "Por favor, seleccione un GSD mayor o elija otro formato.",
              "okButton": "Ok"
            },
            "form": {
              "labels": {
                "layer": "Capa",
                "format": "Formato",
                "fullMap": "Mapa completo",
                "cropArea": "Área recortada",
                "resolution": "GSD",
                "density": "Densidad (en %)",
                "interval": "Intervalo",
                "source": "Fuente",
                "numVertices": "Número de vértices",
                "crs": "Sistema de coordenadas",
                "localCrs": "Mostrar solo sistemas de coordenadas de la región.",
                "share": "Compartir",
                "sendMap": "Enviar Email"
              },
              "placeholders": {
                "layer": "Seleccionar la capa",
                "format": "Seleccionar el formato",
                "resolution": "Seleccionar la resolución",
                "interval": "Seleccionar el intervalo",
                "crs": "Seleccionar el sistema de coordenadas",
                "share": "Escriba un correo electrónico y presione Enter",
                "source": "Seleccionar la fuente"
              },
              "errorCaption": {
                "invalid": "Correo electrónico inválido",
                "duplication": "Correo electrónico ya listado"
              }
            },
            "layersTable": {
              "orthomosaic": "Mapa 2D - Ortomosaico",
              "dsm": "Modelo de Superficie Digital",
              "dtm": "Modelo de Terreno Digital",
              "slope": "Gradiente de Pendiente",
              "mesh_3d": "Modelo 3D",
              "point_cloud": "Nube de Puntos",
              "contour_lines": "Líneas de Contorno"
            },
            "formatsTable": {
              "orthomosaic": {
                "tif": "GeoTIFF Comprimido",
                "jpg": "JPEG",
                "png": "PNG",
                "raw": "GeoTIFF Sin Comprimir"
              },
              "dsm": {
                "color_tif": "GeoTIFF Colorizado",
                "jpg": "JPEG",
                "png": "PNG",
                "tif": "GeoTIFF (Valores de Elevación)"
              },
              "dtm": {
                "color_tif": "GeoTIFF Colorizado",
                "jpg": "JPEG",
                "png": "PNG",
                "tif": "GeoTIFF (Valores de Elevación)"
              },
              "mesh_3d": {
                "obj": "Archivo OBJ"
              },
              "point_cloud": {
                "las": "Archivo LAS",
                "laz": "Archivo LAZ"
              },
              "contour_lines": {
                "dxf": "Archivo DXF",
                "shp": "Shapefile (SHP)"
              }
            },
            "sourcesTable": {
              "dsm": "Modelo de Superficie Digital",
              "dtm": "Modelo de Terreno Digital"
            }
          },
          "settings": {
            "title": "Configuración del Proyecto",
            "leaveRouteDescription": "El proyecto no será modificado.",
            "successfulUpdate": "Proyecto ${name} modificado",
            "failedUpdate": "Error. Intente de nuevo."
          },
          "reports": {
            "title": "Informes del Proyecto",
            "form": {
              "labels": {
                "reportType": "Tipo",
                "processing": "Vuelo",
                "annotation": "Anotación",
                "axisFolder": "Secciones",
                "report": "Informe",
                "generate": "Generar Informe",
                "refXML": "Proyecto de Referencia",
                "referenceDate": "Fecha de Referencia"
              },
              "placeholders": {
                "reportType": "Seleccione Tipo de Informe",
                "axisFolder": "Seleccione la Carpeta de Secciones",
                "refXML": "Seleccione el Proyecto de Referencia",
                "annotation": "Seleccione Anotación"
              }
            },
            "axisTable": {
              "table": "Informe de Cálculo de Volumen",
              "graph": "Memoria de Cálculo"
            },
            "reportsTable": {
              "accuracy": "Informe de Precisión",
              "progression": "Informe de Progresión",
              "volume": "Informe de Área y Volumen",
              "axis": "Informe del Eje"
            },
            "volume": {
              "titles": {
                "TRIANGULATED": "Triangulado",
                "PLANE": "Plano",
                "COMPARATIVE": "Comparación entre Superficies"
              }
            },
            "sectionsError": "NT: Erro interno em cálculo, por favor gere novas seções.",
          }
        }
      }
    },
    "process": {
      "flightList": {
        "empty": {
          "title": "Cargar un nuevo vuelo",
          "text": "Haga clic en el botón de arriba para cargar un vuelo"
        }
      }
    },
    "flight": {
      "projectDropDown": {
        "placeholder": "Seleccione el proyecto o cree uno nuevo..."
      },
      "darkMode": {
        "label": "Modo Oscuro"
      }
    },
    "annotationtooltip": {
      "createPoint": "Haga clic en el mapa para agregar un marcador o ingrese coordenadas.",
      "createLine0": "Haga clic en el mapa para comenzar a dibujar la línea o ingrese las coordenadas iniciales.",
      "createLine1": "Haga clic en el mapa para continuar el dibujo.",
      "createLine2": "Haga clic en el último punto creado para finalizar.",
      "createPolygon0": "Haga clic en el mapa para comenzar a dibujar el área o ingrese las coordenadas iniciales.",
      "createPolygon1": "Haga clic en el mapa para continuar el dibujo.",
      "createPolygon2": "Haga clic en el primer punto creado para finalizar."
    },
    "movefoldermodal": {
      "moveHeader": "Mover anotación a carpeta",
      "selectMove": "Seleccione o cree la carpeta deseada",
      "movePlaceholder": "Seleccione o cree carpeta...",
      "move": "Mover",
      "copyHeader": "Copiar anotación a otro Vuelo",
      "selectProcessing": "Seleccione el Vuelo deseado",
      "copyPlaceholder": "Seleccione Vuelo...",
      "cancel": "Cancelar",
      "copy": "Copiar",
      "placeholder": "Seleccione el elemento de comparación..."
    },
    "moveProjectToFolderModal": {
      "moveHeader": "Mover proyecto a carpeta",
      "selectMove": "Seleccione la carpeta deseada",
      "movePlaceholder": "Seleccione carpeta...",
      "move": "Mover",
      "cancel": "Cancelar"
    },
    "outsidemodal": {
      "header": "Anotaciones fuera del área de procesamiento",
      "body": "Lista de anotaciones que no pudieron ser importadas",
      "name": "Nombre",
      "filename": "AnotacionesFueraDeLímites",
      "csvHeader": "NOMBRE;X;Y",
      "okButton": "Ok",
      "downloadButton": "Descargar .csv"
    },
    "bottombar": {
      "placeholder": "Escriba un comentario..."
    },
    "coordinateoverlay": {
      "body": "Ingrese la coordenada correspondiente al punto marcado en la imagen"
    },
    "timeline": {
      "processingSelect": "Selección de Vuelo",
      "confirmSelection": "Confirmar Selección"
    }
  },
  "login": {
    "wait": "Espere...",
    "unlocked": "Usuario desbloqueado. Por favor, inicie sesión en su cuenta",
    "successRegister": "Registro exitoso. Por favor, inicie sesión en su cuenta",
    "successNewPassword": "¡Contraseña cambiada exitosamente!",
    "successEmail": "Si esta dirección de correo electrónico está en nuestra base, se ha enviado un correo con instrucciones para restablecer la contraseña. Revise su bandeja de entrada o spam.",
    "errorLocked": "Excedió el número permitido de intentos, el usuario ha sido bloqueado. Se enviará un correo con instrucciones para desbloquear.",
    "errorCredentials": "Se encontró un error con sus credenciales, verifique o recupere la contraseña.",
    "errorEmailSSO": "El correo electrónico del IdP no coincide con el de la invitación",
    "errorConnection": "Se encontró un problema al comunicarse con los servidores. Por favor, intente de nuevo.",
    "errorInternal": "Error Interno",
    "errorToken": "Token Inválido",
    "errorValidation": "Hubo un problema al validar su identidad, revise su conexión.",
    "errorExistingUser": "Usuario ya registrado en la plataforma, por favor regístrese con un nombre de usuario diferente.",
    "accountAccess": "Acceso a la Cuenta",
    "enterEmail": "Ingrese correo electrónico",
    "formatEmail": "Por favor, proporcione un correo electrónico válido",
    "enterPassword": "Ingrese contraseña",
    "formatPassword": "Por favor, proporcione una contraseña válida",
    "remember": "Recuérdame",
    "register": "Registrar Cuenta",
    "registerButton": "Registrar",
    "enter": "Ingresar",
    "continue": "Continuar",
    "confirmPassword": "Confirme su contraseña",
    "notEqual": "Las contraseñas no son iguales",
    "validatePassword": "Su contraseña debe contener letras minúsculas, mayúsculas, números y un carácter especial",
    "selectIndustry": "Seleccione el sector",
    "validateIndustry": "Por favor, informe su sector",
    "enterName": "Ingrese su nombre",
    "validateName": "Por favor, informe su nombre",
    "forgotPassword": "Olvidé mi contraseña",
    "new": "¿Nuevo Usuario?",
    "contact": "Contáctenos",
    "hasAccount": "¿Ya tiene una cuenta?",
    "clickHere": "Haga Clic Aquí",
    "terms": "Términos de uso",
    "policy": "Política de Privacidad",
    "rights": "Todos los derechos reservados."
  },
  "password": {
    "reenter": "Restablezca su contraseña",
    "validatePassword": "Su contraseña debe contener letras minúsculas, mayúsculas, números y un carácter especial",
    "enterEmail": "Ingrese correo electrónico",
    "formatEmail": "Por favor, proporcione un correo electrónico válido",
    "enterPassword": "Ingrese contraseña",
    "confirmPassword": "Confirme su contraseña",
    "notEqual": "Las contraseñas no son iguales",
    "request": "Solicitar"
  },
  "accordion": {
    "collapse": "Colapsar",
    "expand": "Expandir"
  },
  "breadcrumbs": {
    "ariaLabel": "Navegación de migas de pan"
  },
  "datepicker": {
    "ariaLabel": "Seleccione una fecha.",
    "ariaLabelRange": "Seleccione un rango de fechas.",
    "ariaLabelCalendar": "Calendario.",
    "ariaRoleDescriptionCalendarMonth": "Mes del calendario",
    "previousMonth": "Mes anterior.",
    "nextMonth": "Mes siguiente.",
    "pastWeek": "Semana pasada",
    "pastMonth": "Mes pasado",
    "pastThreeMonths": "Últimos 3 meses",
    "pastSixMonths": "Últimos 6 meses",
    "pastYear": "Último año",
    "pastTwoYears": "Últimos 2 años",
    "screenReaderMessageInput": "Presione la tecla de flecha hacia abajo para interactuar con el calendario y seleccionar una fecha. Presione escape para cerrar el calendario.",
    "selectedDate": "La fecha seleccionada es ${date}.",
    "selectedDateRange": "El rango de fechas seleccionado es desde ${startDate} hasta ${endDate}.",
    "selectSecondDatePrompt": "Seleccione la segunda fecha.",
    "quickSelectLabel": "Elija un rango de fechas",
    "quickSelectAriaLabel": "Elija un rango de fechas",
    "quickSelectPlaceholder": "Ninguno",
    "timeSelectEndLabel": "Hora de finalización",
    "timeSelectStartLabel": "Hora de inicio",
    "timePickerAriaLabel12Hour": "Seleccione una hora, formato de 12 horas.",
    "timePickerAriaLabel24Hour": "Seleccione una hora, formato de 24 horas.",
    "timezonePickerAriaLabel": "Seleccione una zona horaria.",
    "selectedStartDateLabel": "Fecha de inicio seleccionada.",
    "selectedEndDateLabel": "Fecha de finalización seleccionada.",
    "dateNotAvailableLabel": "No disponible.",
    "dateAvailableLabel": "Está disponible.",
    "selectedLabel": "Seleccionado.",
    "chooseLabel": "Elegir"
  },
  "datatable": {
    "emptyState": "No hay filas que coincidan con los criterios de filtro definidos. Por favor, elimine uno o más filtros para ver más datos.",
    "loadingState": "Cargando filas.",
    "searchAriaLabel": "Buscar por texto",
    "filterAdd": "Agregar Filtro",
    "filterExclude": "Excluir",
    "filterApply": "Aplicar",
    "filterExcludeRange": "Excluir rango",
    "filterExcludeValue": "Excluir valor",
    "filterAppliedTo": "filtro aplicado a",
    "optionsLabel": "Seleccione columna para filtrar por",
    "optionsSearch": "Buscar una columna para filtrar por...",
    "optionsEmpty": "No hay columnas disponibles.",
    "categoricalFilterSelectAll": "Seleccionar Todo",
    "categoricalFilterSelectClear": "Limpiar",
    "categoricalFilterEmpty": "No se encontraron categorías",
    "datetimeFilterRange": "Rango",
    "datetimeFilterRangeDatetime": "Fecha, Hora",
    "datetimeFilterRangeDate": "Fecha",
    "datetimeFilterRangeTime": "Hora",
    "datetimeFilterCategorical": "Categórico",
    "datetimeFilterCategoricalWeekday": "Día de la semana",
    "datetimeFilterCategoricalMonth": "Mes",
    "datetimeFilterCategoricalQuarter": "Trimestre",
    "datetimeFilterCategoricalHalf": "Semestre",
    "datetimeFilterCategoricalFirstHalf": "H1",
    "datetimeFilterCategoricalSecondHalf": "H2",
    "datetimeFilterCategoricalYear": "Año",
    "numericalFilterRange": "Rango",
    "numericalFilterSingleValue": "Valor Único",
    "booleanFilterTrue": "verdadero",
    "booleanFilterFalse": "falso",
    "booleanColumnTrueShort": "V",
    "booleanColumnFalseShort": "F"
  },
  "buttongroup": {
    "ariaLabel": "grupo de botones"
  },
  "fileuploader": {
    "dropFilesToUpload": "Suelte los archivos aquí para cargarlos",
    "or": "o",
    "browseFiles": "Examinar archivos",
    "retry": "Reintentar carga",
    "cancel": "Cancelar"
  },
  "menu": {
    "noResultsMsg": "Sin resultados",
    "parentMenuItemAriaLabel": "Actualmente está en un elemento que abre una lista anidada. Presione la flecha derecha para ingresar a ese elemento y la flecha izquierda para regresar."
  },
  "modal": {
    "close": "Cerrar"
  },
  "drawer": {
    "close": "Cerrar"
  },
  "pagination": {
    "prev": "Anterior",
    "next": "Siguiente",
    "preposition": "de"
  },
  "select": {
    "noResultsMsg": "No se encontraron resultados",
    "placeholder": "Seleccionar...",
    "create": "Crear"
  },
  "toast": {
    "close": "Cerrar"
  }
};
